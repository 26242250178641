@import url("https://fonts.googleapis.com/css2?family=Days+One&display=swap");

body {
  font-family: "Days One", sans-serif;
  background-color: black;
  color: white;
}

.App {
  text-align: center;
}
