.main {
  margin-top: 100px;
}

.main > h1 {
  color: turquoise;
  font-size: 50px;
}

.buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.search {
  background-color: yellowgreen;
  margin: 50px;
  padding: 15px;
  width: 200px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
  border: none;
  box-sizing: border-box;
  transition: all 500ms ease;
}

.search:hover {
  color: white;
  border: white solid 1px;
  background: green;
  cursor: pointer;
}

#addbtn {
  background-color: tomato;
  border: none;
  padding: 15px;
  width: 200px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
  box-sizing: border-box;
  transition: all 500ms ease;
}

#addbtn:hover {
  cursor: pointer;
  color: white;
  border: white solid 1px;
  background: red;
}

footer {
  margin-top: 100px;
}
