.bot {
  border: 1px solid red;
  margin-top: 50px;
  padding-bottom: 50px;
  border-radius: 50px;
}

.name {
  padding: 10px;
  width: 300px;
}

.name::placeholder {
  font-family: "Days One", sans-serif;
}

.sub {
  margin-top: 50px;
  width: 200px;
  height: 50px;
  border-radius: 50px;
  background-color: cornflowerblue;
  cursor: pointer;
}

@media (max-width: 600px) {
  .name {
    margin-top: 0px;
  }
}

h1 {
  font-size: 50px;
}

.select {
  margin-top: 30px;
  height: 50px;
}

.select > option {
  padding: 10px;
  margin-top: 50px;
  width: 300px;
}

.form {
  margin-top: 150px;
}

.place {
  margin-top: 200px;
}
