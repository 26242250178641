/* Dropdown Button */
.dropbtn {
  background-color: #02b2be;
  color: black;
  padding: 16px;
  font-size: 16px;
  font-weight: 700;
  border: none;
  width: 20rem;
  cursor: pointer;
  border-radius: 10px;
  font-family: "Days One", sans-serif;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  margin-top: 70px;
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  text-decoration: none;
  max-height: 300px;
  overflow: scroll;
}

/* Links inside the dropdown */
.dropdown-content button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 20rem;
  text-decoration: none;
  cursor: pointer;
}

/* Change color of dropdown links on hover */
.dropdown-content button:hover {
  background-color: #ddd;
  width: 20rem;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.list > table {
  border: 1px solid rgb(255, 0, 0);
  width: 100%;
  margin-top: 50px;
  padding: 5px;
  text-align: center;
  background-color: white;
  text-decoration: none;
  color: black;
}
