@import url(https://fonts.googleapis.com/css2?family=Days+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: "Days One", sans-serif;
  background-color: black;
  color: white;
}

.App {
  text-align: center;
}

.main {
  margin-top: 100px;
}

.main > h1 {
  color: turquoise;
  font-size: 50px;
}

.buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 10px;
}

.search {
  background-color: yellowgreen;
  margin: 50px;
  padding: 15px;
  width: 200px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
  border: none;
  box-sizing: border-box;
  transition: all 500ms ease;
}

.search:hover {
  color: white;
  border: white solid 1px;
  background: green;
  cursor: pointer;
}

#addbtn {
  background-color: tomato;
  border: none;
  padding: 15px;
  width: 200px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
  box-sizing: border-box;
  transition: all 500ms ease;
}

#addbtn:hover {
  cursor: pointer;
  color: white;
  border: white solid 1px;
  background: red;
}

footer {
  margin-top: 100px;
}

/* Dropdown Button */
.dropbtn {
  background-color: #02b2be;
  color: black;
  padding: 16px;
  font-size: 16px;
  font-weight: 700;
  border: none;
  width: 20rem;
  cursor: pointer;
  border-radius: 10px;
  font-family: "Days One", sans-serif;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  margin-top: 70px;
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  text-decoration: none;
  max-height: 300px;
  overflow: scroll;
}

/* Links inside the dropdown */
.dropdown-content button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 20rem;
  text-decoration: none;
  cursor: pointer;
}

/* Change color of dropdown links on hover */
.dropdown-content button:hover {
  background-color: #ddd;
  width: 20rem;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.list > table {
  border: 1px solid rgb(255, 0, 0);
  width: 100%;
  margin-top: 50px;
  padding: 5px;
  text-align: center;
  background-color: white;
  text-decoration: none;
  color: black;
}

th {
  text-align: center;
  padding: 5px;
  border-bottom: 3px solid red;
  cursor: pointer;
  text-decoration: none;
}

.bot {
  border: 1px solid red;
  margin-top: 50px;
  padding-bottom: 50px;
  border-radius: 50px;
}

.name {
  padding: 10px;
  width: 300px;
}

.name::-webkit-input-placeholder {
  font-family: "Days One", sans-serif;
}

.name:-ms-input-placeholder {
  font-family: "Days One", sans-serif;
}

.name::placeholder {
  font-family: "Days One", sans-serif;
}

.sub {
  margin-top: 50px;
  width: 200px;
  height: 50px;
  border-radius: 50px;
  background-color: cornflowerblue;
  cursor: pointer;
}

@media (max-width: 600px) {
  .name {
    margin-top: 0px;
  }
}

h1 {
  font-size: 50px;
}

.select {
  margin-top: 30px;
  height: 50px;
}

.select > option {
  padding: 10px;
  margin-top: 50px;
  width: 300px;
}

.form {
  margin-top: 150px;
}

.place {
  margin-top: 200px;
}

